// specific screens

@mixin xsmall {
    @media only screen and (max-width: 599px) and (orientation: portrait) {
        @content;
    }
    @media only screen and (max-height: 599px) and (orientation: landscape) and (hover: none) {
        @content;
    }
}
@mixin small {
    @media only screen and (min-width: 600px) and (max-width: 959px) and (orientation: portrait) {
        @content;
    }
    @media only screen and (max-height: 959px) and (orientation: landscape) and (hover: none) {
        @content;
    }
}

@mixin medium {
    @media only screen and (min-width: 960px) and (max-width: 1439px) {
        @content;
    }
}

@mixin large {
    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
        @content;
    }
}
@mixin xlarge {
    @media only screen and (min-width: 1920px) {
        @content;
    }
}

// specific MINIMUM resolution

@mixin medium-and-larger {
    @media only screen and (min-width: 960px) {
        @content;
    }
}
@mixin large-and-larger {
    @media only screen and (min-width: 1440px) {
        @content;
    }
}

// specific MAXIMUM resolution

@mixin small-and-smaller {
    @media only screen and (max-width: 959px) {
        @content;
    }
}

:root {
    //just for reference in inspector
    @include xsmall {
        --media: xsmall;
    }
    @include small {
        --media: small;
    }
    @include medium {
        --media: medium;
    }
    @include large {
        --media: large;
    }
    @include xlarge {
        --media: xlarge;
    }
}
