.sidebar-gradient {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% + 1rem);
    height: 6rem;
    margin-left: -1rem;
    background: linear-gradient(90deg, rgba(51, 155, 164, 1) 0%, rgba(22, 65, 148, 1) 100%);
}

.sidebar-corner {
    position: absolute;
    bottom: 1rem;
    right: 0;
    width: 100%;
    height: 5rem;
    // background: linear-gradient(90deg, rgba(51, 155, 164, 1) 0%, rgba(22, 65, 148, 1) 100%);
    background: url("~src/assets/ui/pm-sidebar-corner.svg");
    background-size: cover;
    background-position: 100%;
}
