.button {
    display: inline-block;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    box-sizing: border-box;
    border: 2px solid $color-dark-grey;
    // text-transform: uppercase;
}

// .button:not(:first-child) {
//     margin-left: 16px;
// }

.button.red {
    color: white;
    background-color: $pm-darkblue;
    border-color: $pm-darkblue;
}
.button.sea {
    color: white;
    background-color: $pm-seablue;
    border-color: $pm-seablue;
}

.button.rounded {
    // border-radius: $border-radius;
}

.button.red-outline {
    background-color: transparent;
    color: $pm-seablue;
    border: 2px solid $pm-seablue;
}
.button.darkblue-outline {
    background-color: transparent;
    color: $pm-darkblue;
    border: 2px solid $pm-darkblue;
}
.button.sea-outline {
    background-color: transparent;
    color: $pm-seablue;
    border: 2px solid $pm-seablue;
}
.button.white-outline {
    color: white;
    border: 2px solid white;
}
.button.grey-outline {
    color: $color-dark-grey;
    border: 2px solid $color-dark-grey;
}

.button.small-padding {
    padding: 6px;
}

.button.small-ish {
    // padding: 4px 10px;
    // font-size: 10px;

    // font-weight: bold;
    font-size: 0.7rem;

    padding: 0.5rem 2rem;
}
.button.small {
    // padding: 4px 10px;
    // font-size: 10px;

    font-weight: bold;
    font-size: 0.7rem;

    padding: 0.3rem 2rem;
}

.button.text {
    border-color: transparent;
    text-decoration: underline;
    span {
        text-decoration: underline;
    }
}

.button.with-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    img,
    svg {
        height: 0.7rem;
        width: auto;
    }
}

// grow effect

a.hover,
.button {
    transition:
        transform 0.25s,
        filter 0.25s;
    cursor: pointer;
}

a.hover:hover,
.button:hover {
    transform: scale(1.05);
}

a.tab {
    // padding: 2rem 2.5rem 0.5rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    height: 3rem;
    padding-bottom: 0.5rem;
    box-sizing: border-box;
    // border-bottom-width: 2px;
    // border-color: white;
    // border-style: solid;

    color: black;
    font-size: 0.7rem;
    &:not(:last-child) {
        // margin-right: 0.5rem;
    }

    &.active {
        font-weight: bold;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);

        &:after {
            content: " ";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 3px;
            background: linear-gradient(90deg, rgba(51, 155, 164, 1) 0%, rgba(22, 65, 148, 1) 100%);
        }
    }
}
