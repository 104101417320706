body {
    width: 100%;
    height: 100%;
}
@import "shared/__shared.scss";

@import "_layouting.scss";
@import "_typography.scss";
@import "style-scrollbar.scss";

@import "style-button.scss";
@import "style-whiteblock.scss";
@import "style-forms.scss";
@import "style-dropdown.scss";

@import "pm.scss";

//cookie consent shizzle
// .ucc-backdrop {
//     background-color: rgba(0, 0, 0, 0.25);
// }

:root {
    --ucc-backdrop-color: rgba(255, 255, 255, 0.8);
    --ucc-modal-background: #{$pm-seablue};
    --ucc-modal-width: 600px;
    --ucc-modal-padding: 3rem;
    --ucc-font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    --ucc-button-spacing: 2rem;

    --ucc-color-primary: #{$pm-darkblue};
    --ucc-color-primary-contrast: white;
    --ucc-color-secondary: #{$color-medium-grey};
    --ucc-color-secondary-contrast: white;

    --ucc-switch-width: 2rem;
    --ucc-switch-height: 1rem;

    @include small-and-smaller {
        --ucc-modal-width: calc(100% - 4rem);
    }
}

.ucc-modal {
    color: white !important;
    min-height: auto !important;

    a,
    a:hover,
    a:focus,
    a:visited,
    a:active {
        color: white !important;
        // font-weight: bold !important;
        text-decoration: underline !important;
    }

    .ucc-button.ucc-button--secondary {
        display: none !important;
    }

    .ucc-buttons {
        justify-content: center !important;
    }
}

.ucc-button {
    padding: 1rem 5rem !important;
    font-size: 25px !important;
    border-radius: $border-radius !important;
}
