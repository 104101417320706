input[type="text"] {
    font-family: "Roboto Condensed", sans-serif;
    background-color: transparent;
    color: black;
    box-shadow: none;
    border-style: none;
    border-bottom: 1px solid black;

    // font-size: 16px;
    // display: inline;
    font-weight: 400;
    font-size: 0.7rem;

    padding: 0.3rem;

    &::placeholder {
        color: $color-light-grey;
    }
}

.radio.row {
    // flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .radio-button {
        // min-width: 8rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:not(:last-child) {
            margin-right: 1.5rem;
        }
        img {
            // display: none;
        }

        padding: 0;
        // padding: 0.5rem 2rem;
        padding-left: 0;
        padding-top: 0;
        // background-color: white;
        // border-radius: $border-radius;

        box-sizing: border-box;
        // border: 2px solid $pm-darkblue;

        font-size: 0.8rem;

        color: black;

        &.selected {
            // background-color: $pm-darkblue;
            // font-weight: bold;
            // color: white;
        }
    }
}
