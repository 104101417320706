html,
body {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
}

@import "_responsiveness.scss";
@import "_variables.scss";
@import "_layouting-mixins.scss";
@import "_styling-mixins.scss";
