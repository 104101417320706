$pver: 0.3rem;
$phor: 0.5rem;
$list-right: 1.5rem;

// generic
app-dropdown {
    font-size: 0.7rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .blocker {
        z-index: 9;
    }

    a.dropdown {
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;

        padding: $phor $pver;
        font-size: 0.7rem;
        color: black;
    }

    .dropdown-list {
        z-index: 10;
        // width: auto;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        margin-top: 0.5rem;

        border-radius: 10px;

        .dropdown-item {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            span {
                word-break: keep-all;
                line-break: loose;
                break-inside: avoid;
                white-space: nowrap;
                margin: 0;
            }

            img {
                margin-right: $phor;
            }
        }

        .dropdown-item:not(:first-child) {
            padding-bottom: calc(0.5 * #{$phor});
            padding-top: calc(0.5 * #{$phor});
        }

        .footer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            text-align: center;
            align-self: stretch;
        }
    }
}

app-dropdown.filterpage.right .dropdown-list {
    right: 0;
    left: auto;
}
// filterpage dropdown styling
app-dropdown.filterpage {
    &:not(:last-child) {
        margin-right: 1rem;
    }

    a.dropdown {
        // text-transform: uppercase;
        box-sizing: border-box;
        border: 2px solid white;
        border-radius: $border-radius;
        background-color: white;
        width: 5rem;
        @include small-and-smaller {
            width: 8rem;
        }
        font-size: 0.7rem;
        color: black;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        transition:
            color 0.25s ease,
            background-color 0.25s ease;

        &.open {
            background-color: white;
            color: black;
        }

        .arrow {
            width: 15px;
            height: auto;
            margin-left: 10px;
        }
    }

    .dropdown-list {
        background-color: white;
        // border: 1px solid $color-medium-grey-2;

        width: auto;
        min-width: 100%;
        .dropdown-item {
            font-size: 0.7rem;
            padding: $phor $list-right $phor $pver;

            img {
                width: 18px;
                height: 18px;
            }
        }

        .footer {
            font-size: 80%;
            font-weight: bold;
            text-transform: uppercase;
            color: $color-dark-red;

            padding: 6px;
            margin-left: 6px;
            margin-right: 6px;
            margin-top: $pver;
            border-top: 1px solid $color-medium-grey-2;
        }
    }
}

// configpage dropdown styling
app-dropdown.configpage {
    a.dropdown {
        border-bottom: 1px solid black;
        justify-content: space-between;
        .arrow {
            width: 15px;
            height: auto;
            margin-left: 10px;
        }
    }

    .dropdown-list {
        background-color: white;
        .dropdown-item {
            padding: 0.25rem 0.5rem;
            img {
                width: 10px;
                height: 10px;
            }
        }
    }
}
