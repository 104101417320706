// scrollbar
::-webkit-scrollbar {
    width: 10px;
    // border: 1px solid $pm-grey;
    // border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
}
// arrow buttons
::-webkit-scrollbar-button {
    display: none;
}
// drag handle
::-webkit-scrollbar-thumb {
    // border: 1px solid $pm-grey;
    background-color: $pm-darkblue;
    border-radius: 5px;
}
// scroll-area bg
::-webkit-scrollbar-track {
    background-color: $pm-grey;

    background-color: transparent;
    // border-radius: 5px;
}
// scroll-area bg not covered by handle
::-webkit-scrollbar-track-piece {
    // background-color: $pm-grey;
    background-color: transparent;
    // border-radius: 5px;
}
// resize handle
::-webkit-resizer {
    display: none;
}
