@mixin full-frame {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
@mixin full-frame-fixed {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

@mixin cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
@mixin contain {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
