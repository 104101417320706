.full-frame {
    @include full-frame;
}

.full-frame-fixed {
    @include full-frame-fixed;
}

.cover {
    @include cover;
}

.contain {
    @include contain;
}

//blocks mouse events
.non-blocking {
    pointer-events: none;
}

//un-block mouse events
.blocking {
    pointer-events: initial;
}

//don't show overflow
.no-overflow {
    overflow: hidden;
}

//cursor pointer with mouse-events enabled
.pointer {
    cursor: pointer;
    pointer-events: initial;
}

//visibility state
.hidden {
    display: none !important;
    pointer-events: none !important;
}

//animating fade
.fade-out {
    animation: fade-out 0.25s;
}

.fade-in {
    animation: fade-in 0.25s;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

//routing fix for angular
router-outlet {
    width: 0;
    height: 0;
    display: none;
}

// scroll container

@mixin scroll-parent-flex {
    flex: 1;
    align-self: stretch;
    position: relative;
}

@mixin scroll-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // overflow-y: auto;
}

// we need a flex container that will grow
.scroll-parent-flex:not(.desktop-only) {
    @include scroll-parent-flex;
}

//and this is the absolute positioned element that stretches to the container and is scrollable
.scroll-container:not(.desktop-only) {
    @include scroll-container;
}

// we need a flex container that will grow
.scroll-parent-flex.desktop-only {
    @include medium-and-larger {
        @include scroll-parent-flex;
    }
}

//and this is the absolute positioned element that stretches to the container and is scrollable
.scroll-container.desktop-only {
    @include medium-and-larger {
        @include scroll-container;
    }
}

.tab-bar {
    display: flex;
    flex-direction: row;
    // padding-left: 2rem;
    // padding-right: 2rem;
    background-color: #ebf1f6;
    position: relative;

    > * {
        flex-grow: 1;
        position: relative;
    }

    .invisible {
        opacity: 0;
        user-select: none;
        cursor: initial;
        pointer-events: none;
    }
}
