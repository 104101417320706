@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;700&display=swap");
//sample variables
$color-bg: #efefef;
$color-light-grey: #c2c8cc;
$color-medium-grey: #565854;
$color-medium-grey-2: #707070;
$color-dark-grey: #263238;
$color-red: #e2423d;
$color-dark-red: #c63825;

$border-radius: 5px;

//pontmeyer
$pm-seablue: #339ba4;
$pm-darkblue: #164194;
$pm-darkerblue: #0c214b;
$pm-grey: #eceff1;
$pm-bg-grey: #f1f6f9;
