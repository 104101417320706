:root,
html,
body,
input,
button,
a {
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: $color-dark-grey;
    font-size: 25px;
    line-height: 1.4;

    @include medium {
        font-size: 16px;
    }
    @include small-and-smaller {
        font-size: 16px;
    }
    @include xlarge {
    }
    @include medium-and-larger {
        font-size: 1.3vw;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
        font-size: 25px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 25px;
    }
}

a,
input,
button {
    text-decoration: none;
    outline: none;
    appearance: none;
    cursor: pointer;
    border-radius: 0;
    border-width: 0;
}

p {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto Condensed", Georgia, "Times New Roman", Times, serif;
    font-weight: 700;
    margin: 0;
    margin-bottom: 0.75rem;
}

h4 {
    font-weight: 500;
}

.bold {
    font-weight: bold;
}

// generic disabled
.disabled {
    filter: opacity(0.5);
    pointer-events: none;
    cursor: initial;
}
