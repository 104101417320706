.white-block-outer {
    @include medium-and-larger {
        @include full-frame;
    }
    @include small-and-smaller {
        padding: 1rem;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .white-block {
        background-color: white;
        color: $color-medium-grey-2;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        // width: 100%;
        align-items: stretch;
        padding: 1rem;

        @include medium-and-larger {
            width: auto;
            max-width: 75%;
        }

        h2,
        h3 {
            // color: $color-medium-grey-2;
            color: black;
        }
    }
}
